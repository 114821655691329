<template>
  <vx-card :title="title">
    <div>
      <territory @data="setTerritoryId"></territory>
      <div class="vx-row mb-6" style="width:70%">
        <div class="vx-col sm:w-1/4 w-full">
          <span>Valid From - To</span>
        </div>
        <div class="vx-col sm:w-3/4 w-full">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd Mon yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="validity"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ dateFormat(picker.startDate) }} -
              {{ dateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
        </div>
      </div>
      <div class="vx-row mb-12">
        <div class="vx-col md:w-1/1 w-full mb-base">
          <data-table
            :territorryId="territorryId"
            :territoryCode="territoryCode"
            :territoryName="territoryName"
            :startDate="validity.startDate"
            :endDate="validity.endDate"
          ></data-table>
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
import DataTable from "./DataTable";
import Territory from "./Territory";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
  components: {
    DataTable,
    Territory,
    DateRangePicker,
  },
  data() {
    return {
      title: "Customer Survey Monitoring",
      territorryId: null,
      territoryCode: null,
      validity: {
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    setTerritoryId(id, code, name) {
      this.territorryId = id;
      this.territoryCode = code
      this.territoryName = name
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
  },
  computed: {},
};
</script>
  
<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.ps {
  height: 800px;
}
</style>