<template>
  <div>
    <div class="vx-row ml-1 mt-3">
      <vs-button
        color="success"
        icon-pack="feather"
        icon="icon-filter"
        @click="getData()"
      >Get Data</vs-button>
    </div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      :buttonSearch="true"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th sort-key="supervisor_name">Supervisor</vs-th>
        <vs-th sort-key="sales_name">Sales</vs-th>
        <vs-th sort-key="count_survey">Jumlah Survey</vs-th>
        <vs-th sort-key="count_customer_survey">Jumlah Survey Customer</vs-th>
        <vs-th sort-key="count_survey_filled">Survey Terisi Customer</vs-th>
        <!-- <vs-th sort-key="mandatory">Mandatory</vs-th> -->
        <vs-th></vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="tr.supervisor_name">{{ tr.supervisor_name }}</vs-td>
          <vs-td :data="tr.sales_name">{{ tr.sales_name }}</vs-td>
          <vs-td :data="tr.count_survey">{{ tr.count_survey }}</vs-td>
          <vs-td :data="tr.count_customer_survey">{{ tr.count_customer_survey }}</vs-td>
          <vs-td :data="tr.count_survey_filled">{{ tr.count_survey_filled }}</vs-td>
          <vs-td class="whitespace-no-wrap">
            <feather-icon
              title="Detail"
              icon="ListIcon"
              svgClasses="w-5 h-5 hover:text-success stroke-current"
              @click.stop="handleShowDetail(tr.personal_id, tr.sales_name)"
            />
          </vs-td>
          <!--  -->
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    // customerCategoryId: {
    //   type: Number,
    // },
    // customerCategoryName: {
    //   type: String,
    // },
    territorryId: {
      type: Number,
    },
    territoryCode: {
      type: String,
    },
    territoryName: {
      type: String,
    },
    startDate: {
      type: Date,
    },
    endDate: {
      type: Date,
    },
  },
  data() {
    return {
      baseUrl: "/api/sfa/v1/customer-survey",
      baseUrlOms: "/api/oms/v1/log",
      deleteId: null,
      table: this.tableDefaultState(),
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 5,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      if (this.table.total > 0 && searching.length > 4) {
        this.getData();
      } else if (searching.length == 0){
        this.getData();
      }
    },
    handleChangePage(page) {
      this.table.page = page;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      if (this.table.total > 0) {
        this.getData();
      }
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("Y-MM-DD");
      }
      return a;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/monitoring", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            // customer_category_id: this.customerCategoryId,
            territory_id: this.territorryId,
            date_from: this.startDate == null ? "0001-01-01" : moment(this.startDate).format("YYYY-MM-DD"),
            date_to: this.endDate == null ? "0001-01-01" : moment(this.endDate).format("YYYY-MM-DD"),
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    handleShowDetail(personal_id, sales){
      console.log("Personal ID Sales : ", personal_id)
      let s = this.startDate == null ? "0001-01-01" : moment(this.startDate).format("YYYY-MM-DD")
      let e = this.endDate == null ? "0001-01-01" : moment(this.endDate).format("YYYY-MM-DD")
      this.$router.push("monitoring-detail?id=" + personal_id + "&territory=" + this.territoryCode + "-" + this.territoryName + "&start_date=" + s + "&end_date=" + e + "&sales=" + sales + "&territory_id=" + this.territorryId);
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  watch: {
    // customerCategoryId() {
    //   this.getData();
    // },
    // territorryId() {
    //   this.getData();
    // },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>